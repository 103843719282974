import { createRoot, Suspense } from '@wordpress/element';
import Block from './Block';
import { parseAttributeBooleans } from '../utils';
import { sortBy } from 'lodash';

window.addEventListener( 'DOMContentLoaded', () => {
    const element = document.querySelector(
        '.wp-block-goland-features-frontpage-lift'
    );
    if ( element ) {
        let attributes = { ...element.dataset/*, ...goland*/ };
        attributes = parseAttributeBooleans(attributes);
        attributes.areaRanges = JSON.parse(attributes.areaRanges);
        attributes.customSelectOptions = JSON.parse(attributes.customSelectOptions);
        attributes.customIcons = JSON.parse(attributes.customIcons);
        attributes.button1Styles = JSON.parse(attributes.button1Styles);
        attributes.button2Styles = JSON.parse(attributes.button2Styles);
        const sortedCustomSelectOptions = sortBy(attributes.customSelectOptions, function (o) { return o.name });
        const root = createRoot(element);
        root.render(
            <Suspense fallback={ <div className="wp-block-placeholder" /> }>
                <Block { ...attributes } sortedCustomSelectOptions={sortedCustomSelectOptions} />
            </Suspense>
        );
    }
} );
